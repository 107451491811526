.labelSection {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #201c49;
  display: flex;
  margin-bottom: 8px;

  .requiredIcon {
    color: #ff5273;
    margin-left: 2px;
  }

  .tipIcon {
    width: 20px;
    height: 20px;
    margin-left: 8px;
    color: rgba(65, 66, 201, 1);
  }

  .tipContainer {
    position: relative;
    cursor: pointer;
  }

  .tooltipParent {
    position: absolute;
    left: 40px;
    top: 10px;
    transform: translateY(-50%);
    width: 240px;
    z-index: 10;
  }
}

.helperText {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #6f7999;
  margin-top: 4px;
}

.inputContainer {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #ebedf4;
  border-radius: 4px;

  .input {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #201c49;
    border: 0px;
    border-radius: 4px 0 0 4px;
    padding: 12px 16px;

    &::placeholder {
      color: rgba(0, 0, 0, 0.2);
      font-weight: 400;
    }

    &:focus {
      border-color: inherit;
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    &:disabled,
    &:read-only {
      color: rgba(0, 0, 0, 0.2);
    }
  }

  .textArea {
    box-shadow: none;
    resize: vertical;
    border: none;
    outline: none;
    width: 100%;
    border-radius: 4px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #201c49;

    &::placeholder {
      color: rgba(0, 0, 0, 0.2);
      font-weight: 400;
    }
  }

  .inputRightRadius {
    border-radius: 4px;
  }

  .suffix {
    color: #6f7999;
    font-weight: 400;
    font-size: 14px;
    padding: 0 16px;
    white-space: nowrap;
  }

  .suffixDisabled {
    color: rgba(0, 0, 0, 0.2);
  }

  .selectHeader {
    display: flex;
    align-items: center;
    cursor: pointer;

    .selectValue {
      font-size: 14px;
      line-height: 20px;
      margin-right: 8px;
      white-space: nowrap;
    }

    .selectValueDisabled {
      color: #6f7999;
    }

    .selectIcon {
      width: 16px;
      height: 16px;
      color: rgba(111, 121, 153, 1);
      margin-right: 16px;
    }
  }

  .selectListContainer {
    position: absolute;
    right: 0;
    top: 44px;
  }
}

.inputContainerActive {
  border-color: #4142c9;
}

.inputContainerDisabled {
  background: rgba(0, 0, 0, 0.05);
}

.inputContainerError {
  border-color: #ff5273;
}

.errorContainer {
  display: flex;
  align-items: center;
  margin-top: 4px;
  color: #ff5273;

  .errorIcon {
    width: 13px;
    height: 13px;
  }

  .message {
    margin-left: 5px;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
  }
}

.countCharacter {
  font-weight: 400;
  font-size: 10px;
  color: #6f7999;
  margin-top: 4px;
}
