.default {
  padding: 8px;
  background: #ececfa;
  border-radius: 4px;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #201c49;
}
.title {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #201c49;
}
.content {
  margin-top: 4px;
}
