$primary-main: #4142c9;
$error-main: #ff5273;
$secondary-5: #6f7999;
$gray-400: #9ca3af;
$neutral-300: #d4d4d8;

.inputContainer {
  position: relative;
  display: flex;
  align-items: center;

  .eyeIconContainer {
    position: absolute;
    right: 0;
    padding: 0 16px;
    color: $gray-400;
  }

  .inputBaseStyle {
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    border-radius: 4px;
    padding: 12px 44px 12px 16px;
    border: 1px solid;

    &::placeholder {
      color: rgba(0, 0, 0, 0.2);
    }

    &:disabled,
    &:read-only {
      color: rgba(0, 0, 0, 0.2);
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .input {
    border-color: #ebedf4;
    color: #201c49;

    &:focus {
      border-color: $primary-main;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  .inputError {
    border-color: $error-main;
    color: $error-main;

    &:focus {
      border-color: $error-main;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
}

.validationContainer {
  display: flex;
  column-gap: 20px;
  margin-top: 8px;

  .base {
    display: flex;
    align-items: center;
    column-gap: 4px;
  }

  .defaultIconColor {
    color: $neutral-300;
    div {
      color: $secondary-5;
    }
  }

  .successIconColor {
    color: #9ac301;
  }
}
